import { ReactComponent as CasinoSvg } from "./roll-icon.svg";
import { ReactComponent as PoliciSvg } from "./policy-icon.svg";
import { ReactComponent as TopicsSvg } from "./articles-icon.svg";
import { ReactComponent as HomeSvg } from "./home-icon.svg";
import { ReactComponent as Wave } from "./wave.svg";




const Icons = {
  CasinoSvg,
  PoliciSvg,
  TopicsSvg,
  HomeSvg,
  Wave

};
export default Icons;

