import TopicCard from "../topics/topicCard";
import data from "../../exampleItems/exampleData.json";
import "./home.scss";
import Hero from "./baners/hero";
export default function HomePage(params: any) {
  return (
    <>
      <Hero />

      <div className="container home_page_container">
        <div className="home_topic_container">
          {data.map((item) => (
            <TopicCard item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
