// TopicsContainer.tsx
import React, { useState } from "react";
import "./topics.scss";
import TopicCard from "./topicCard";
import data from "../../exampleItems/exampleData.json";

function TopicsPage() {
  const articlesData = data;

  return (
    <div className="container topics_container">
      <div className="topics_list">
        {articlesData.map((item) => (
          <TopicCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default TopicsPage;
