// TopicCard.tsx
import React from "react";
import { NavLink } from "react-router-dom";

const MAX_TITLE_LENGTH = 27; // Максимальна довжина для Title (ваш вибір)

const truncateTitle = (title: string) => {
  if (title.length > MAX_TITLE_LENGTH) {
    return title.substring(0, MAX_TITLE_LENGTH) + "...";
  }
  return title;
};

const TopicCard: React.FC<any> = ({ item }) => (
  <div className="topics_card_container">
    <NavLink to={`/article/${item.id}`} className="topics_link">
      <img src={item.img} alt={item.title} className="topics_img" />
      <div className="topics_card_desc_container">
        <h3 className="topics_card_title">{truncateTitle(item.title)}</h3>
      </div>
    </NavLink>
  </div>
);

export default TopicCard;
