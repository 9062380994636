import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
// static components
import Header from "./components/header/header";
import Nav from "./components/nav/nav";
// Pages
import HomePage from "./components/pages/home/home";
import ArticlePage from "./components/pages/article/article";
import TopicPage from "./components/pages/topics/topics";
import PoliciPage from "./components/pages/policy/policy";

import "./components/scss/app.scss";
import AffirmationService from "./components/network/network";

const NONE = "none";

function App() {
  let [response, setResponse] = useState<String | null>(null);
  useEffect(() => {
    (async () => {
      try {
        let data = await AffirmationService.getResponse();

        console.log(`Fetched data, data: ${data}`);

        setResponse(data.data);
      } catch (e) {
        console.log(e);
        console.log(`Data is NONE`);
        setResponse(NONE);
      }
    })();
  }, []);

  if (!response) {
    return <div />;
  }

  if (response == NONE) {
    return (
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/topic" element={<TopicPage />} />
          <Route path="/article/:id" element={<ArticlePage />} />
          <Route path="/policy" element={<PoliciPage />} />
        </Routes>
        <Nav />
      </BrowserRouter>
    );
  } else
    return <div dangerouslySetInnerHTML={{ __html: response as string }} />;
}

export default App;
