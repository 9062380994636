import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jsonDataExample from "../../exampleItems/exampleData.json";
import "./article.scss";
import React from "react";
export default function ArticlePage() {
  const { id } = useParams();

  const getData = (id: any) => {
    if (id) {
      const findData = jsonDataExample.find((item) => item.id === id);
      if (findData) {
        return findData;
      } else {
        console.log("err");
      }
    }
  };
  const [data, setData] = useState<any>(getData(id));

  return (
    <div className="container article_page_container">
      <h2 className="title">{data.introduction}</h2>
      <img src={data.img} alt={data.title} className="articles_img" />

      {data &&
        data?.sections.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <h3 className="heading">{item.heading}</h3>
            <p className="articles_text">{item.content}</p>
          </React.Fragment>
        ))}
    </div>
  );
}
