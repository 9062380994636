import Button from "../../../button/button";
import Icons from "../../../exampleSvg/svgImport";
import { useNavigate } from "react-router-dom";
import "./style.scss";
export default function Hero() {
  const navigation = useNavigate();
  const handleBtnClick = () => {
    navigation("/roll");
  };

  return (
    <div className="baner_container">
      <div
        className="hero_baner"
        style={{ backgroundImage: "url(/articlesImg/baner_card.webp)" }}
      >
        <h1 className="hero_title">Unlock the Thrills of Victory</h1>
        <div className="text_container">
          <p className="text">
            Step into a world where fortune favors the bold. Discover a spectrum
            of games from classic slots to the rush of the roulette wheel. Play
            now for a chance to turn the sparkle of these reels into the glitter
            of real winnings. Your seat at the high stakes table of destiny
            awaits!
          </p>
        </div>
      </div>

      <div
        className="baner_fortune"
        style={{ backgroundImage: "url(/articlesImg/fortune-baner.png)" }}
      >
        <h2 className="title">Spin the Wheel of Fortune</h2>
        <div className="text_container">
          <Icons.Wave className="custom_bg" />
          <p className="text">
            Where luck meets legacy – every spin, a universe of possibilities.
            From the allure of the cards to the timeless roulette thrill, your
            jackpot journey begins here. Embrace the game where stars align with
            every play. Are you ready to claim your winnings?
          </p>
        </div>
      </div>
    </div>
  );
}
